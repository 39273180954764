import logo from './logo.svg';
import './App.css';
import './common.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/screens/Home/Home';
import Header from './components/common/Header/Header';
import ContactUs from './components/screens/Contact Us/ContactUs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as MySVG } from "./components/assets/images/scroll_top.svg"
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
        <Routes>
          <Route exact path="/" element={
            <div>
              <Header />
              <Home />
              <ScrollToTop smooth className='scroll_button_top' color="#157fbb" top={120} component={<MySVG className='svg_icon_Top' />} />
            </div>
          } />
          {/* <Route exact path="/ContactUs" element={
            <div>
              <Header />
              <ContactUs />
            </div>
          } /> */}
        </Routes>
      </BrowserRouter>
  );
}

export default App;
