import React,{useEffect,useState} from 'react'
import logo from '../../assets/images/logo.svg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo({top:0,behavior: 'smooth',});
    const header = document.querySelector(".page-header");
    const toggleClass = "is-sticky";
    
    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 100) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    });
  });


  return (
<>
{['md'].map((expand) => (
  <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3 header_main_section page-header">
     {/* <header className=''>
        <nav>
            <a href="" >
               
            </a>
            <ul>
                <li><a href="" ></a></li>
                <li><a href="" ></a></li>
                <li><a href="" ></a></li>
                <li><a href="" ></a></li>
            </ul>
        </nav>
    </header> */}
      <Navbar.Brand href="#" className='logo'>
      <img src={logo} alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle onClick={handleShow} />
      <Navbar.Offcanvas
        // id={`offcanvasNavbar-expand-${expand}`}
        // aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
        placement="end"
        className="offcanvas_header"
        show={show} onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
            {/* Offcanvas */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="justify-content-end flex-grow-1 pe-3">
            <li onClick={handleClose} ><Nav.Link href="#Home" className="home active" >Home</Nav.Link></li>
            <li onClick={handleClose} ><Nav.Link href="#AboutUs" className="About" >About Us</Nav.Link></li>
            <li onClick={handleClose} ><Nav.Link href="#Service" className="Service" >Service</Nav.Link></li>
            <li onClick={handleClose} ><Nav.Link href="#ContactUs" className="Contact" >Contact Us</Nav.Link></li>
          </ul>
          {/* <button>Appoinment</button> */}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
  </Navbar>
))}
</>
  )
}

export default Header