import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutUsImage from '../../assets/images/aboutnew_image.svg';
import kioskImage from '../../assets/images/kiosk_image1.svg';
import homekitImage from '../../assets/images/homwkit_new.svg';
import doctorImage from '../../assets/images/doctor_image.svg';
import mobileArrowImage from '../../assets/images/mobile_arrow_image.svg';
import patientAppImage from '../../assets/images/patient_app_image.svg';
import smartKioskImage from '../../assets/images/smartkiosk_new.svg';
import smartwatchImage from '../../assets/images/smartwatch_new.svg';
import StethoscopeImage from '../../assets/images/Stethoscope_image1.svg';
import bodyTemperatureImage from '../../assets/images/body_temperature_new.svg';
import OtoscopeImage from '../../assets/images/otoscope_new.svg';
import bloodOxygenImage from '../../assets/images/blood_oxygen_image_new.svg';
import glucometerImage from '../../assets/images/glucometer_image_new.svg';
import carouselImage1 from '../../assets/images/carousel_image1new.svg';
import carouselImage2 from '../../assets/images/carousel_image2.svg';
import carouselImage3 from '../../assets/images/carousel_image3new.svg';
import carouselImage4 from '../../assets/images/carousel_image4_new.svg';
import bloodPressureImage from '../../assets/images/blood_pressure_image_new.svg';
import heightWeightImage from '../../assets/images/height_weight_image_new.svg';
import ecgImage from '../../assets/images/ecg_image_new.svg';
import { RiDoubleQuotesL } from "react-icons/ri";
import { LuPlay } from "react-icons/lu";
import { MdOutlinePlayArrow } from "react-icons/md";
import ContactUsImage from '../../assets/images/contactus_image.svg'
import contactUsbackimage from '../../assets/images/contact_us_backimage.svg'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';
import mobileQrImage from '../../assets/images/mobile_qr_image.svg'
import qrAppImage from '../../assets/images/qr_app_image.svg'
import boardMember1 from '../../assets/images/board_member_1_new.svg'
import boardMember2 from '../../assets/images/board_member_2_new.svg'
import { ImQuotesLeft } from "react-icons/im";
import testimonialsImage from '../../assets/images/testimonials_image_new.svg'
import eMedicinePlatform from '../../assets/images/e-medicine_platform_new.svg'
import experiencedDoctor1 from '../../assets/images/experienced_doctor_1_new.svg'
import experiencedDoctor2 from '../../assets/images/experienced_doctor_2_new.svg'
import experiencedDoctor3 from '../../assets/images/experienced_doctor_5_new.svg'
import experiencedDoctor4 from '../../assets/images/experienced_doctor_4_new.svg'
import experiencedDoctor5 from '../../assets/images/experienced_doctor_3_new.svg'
import experiencedDoctor6 from '../../assets/images/experienced_doctor_6_new1.svg'
import followsImage1 from '../../assets/images/follows_image1.svg'
import followsImage2 from '../../assets/images/follows_image2.svg'
import followsImage3 from '../../assets/images/follows_image3.svg'
import followsImage4 from '../../assets/images/follows_image4.svg'
import { HiLocationMarker } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdMail } from "react-icons/md";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousell from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


function Home() {

  const form = useRef();

  const [firstName, setfirstName] = useState()
  const [lastName, setlastName] = useState()
  const [email, setemail] = useState()
  const [mobile, setmobile] = useState()
  const [location, setlocation] = useState()
  const [url, seturl] = useState()

  const formSubmit = (event) => {
    toast.dismiss()
    event.preventDefault();

    if (form.current[0].value && form.current[1].value && form.current[2].value && form.current[3].value && form.current[4].value && form.current[5].value) {
       emailjs
       .sendForm('service_okhsa5j','template_7241uyb', form.current, {
         publicKey: 'KEchcX_SobL4U38Bz',
       })
       .then(
         (res) => {
    console.log(form.current,'99489849')
    console.log(res,'99489849')
    form.current.reset();
    toast.success('Form is submitted')
         },
         (error) => {
           console.log('FAILED...', error.text);
         },
       );
    }
    else {
      toast.error('Please fill all the details')
    }    
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2500 },
      items: 5
    },
    desktopLarge: {
      breakpoint: { max: 2500, min: 1600 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1600, min: 992 },
      items: 3
    },
    tabletLarge: {
      breakpoint: { max: 992, min: 767 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 767, min: 576 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <div>
      <section id="Home">
        <Carousel className="carousel_main_top">
          <Carousel.Item>
            <Carousel.Caption
              style={{
                position: "initial",
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <div className="carousel_item_main">
                <Row className="carousel_item_row">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    className="carousel_col"
                  >
                    <div className="carousel_main_left">
                      <h5>Diagnosed Remotely With Medical Devices</h5>
                      {/* <button><a href="#qrApplication" className='qrApplicationLink'>Appointment</a></button> */}
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    className="carousel_col"
                  >
                    <div
                      className="carousel_main_right"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <img src={carouselImage1} alt="carouselImage1" style={{width: '90%',margin:'auto',backgroundPosition:'top'}} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption
              style={{
                position: "initial",
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <div className="carousel_item_main">
                <Row className="carousel_item_row">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%" }}
                  >
                    <div className="carousel_main_left">
                      <h5>No in-Person Visit</h5>
                      {/* <button><a href="#qrApplication" className='qrApplicationLink'>Appointment</a></button> */}
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%" }}
                  >
                    <div className="carousel_main_right carousel_Two_right">
                      <img
                        src={carouselImage4}
                        alt="carouselImage2"
                        style={{
                          height: "85%",
                          margin: "auto",
                          width: "max-content",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption
              style={{
                position: "initial",
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <div className="carousel_item_main">
                <Row className="carousel_item_row">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%", padding: "0" }}
                  >
                    <div className="carousel_main_left">
                      <h5>Connect with Doctor around the world !</h5>
                      {/* <button><a href="#qrApplication" className='qrApplicationLink'>Appointment</a></button> */}
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%", padding: "0" }}
                  >
                    <div
                      className="carousel_main_right"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <img src={carouselImage3} alt="carouselImage3" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption
              style={{
                position: "initial",
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <div className="carousel_item_main">
                <Row className="carousel_item_row">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%" }}
                  >
                    <div className="carousel_main_left">
                      <h5>Get Connect With Kiosk / Homekit</h5>
                      {/* <button><a href="#qrApplication" className='qrApplicationLink'>Appointment</a></button> */}
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="6"
                    style={{ height: "100%" }}
                  >
                    <div className="carousel_main_right">
                      <img
                        src={carouselImage4}
                        alt="carouselImage4"
                        style={{
                          height: "85%",
                          margin: "auto",
                          width: "max-content",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      {/* ---------------------------------------------------  About us  ----------------------------------------------- */}

      <section id="AboutUs">
        <Row className="aboutUs_row">
          <Col xs="12" sm="12" md="6" lg="7" xl="7" xxl="7" data-aos="fade-right">
            <div className="aboutUs_left">
              <h6>About Us</h6>
              <h3>The worlds best E-Medicine platform that you can trust</h3>
              <p className="description_long">
                Kevell care is a E-Medicine platform providing remote delivery
                of health care services, including consultations and diagnosing
                exams, over the tele communication infrastructure
              </p>
              <p className="description_short">
                Kevell care allows health care providers to evaluate, diagnose
                and treat Patients without the need for an in-person visit.
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            data-aos="fade-left"
          >
            <div className="aboutUs_right">
              <div className="img_div">
                <img src={aboutUsImage} alt="about Us Image" />
                <div className="quation_card" data-aos="fade-up">
                  <span className="Quotesicon">
                    <RiDoubleQuotesL />
                  </span>
                  <ul>
                    <li>Our medical clinic provides quality care for the</li>
                    <li>entire family while maintaining a personable</li>
                    <li>atmosphere best services</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------   video  ----------------------------------------------- */}

      <section>
        <div className="video_main">
          <div className="playbutton_main">
            <button className="play_btn">
              <MdOutlinePlayArrow />
            </button>
          </div>
        </div>
      </section>

      {/* ---------------------------------------------------  Kiosk section  ----------------------------------------------- */}

      <section>
        <Row className="Kiosk_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="kiosk_col"
            data-aos="fade-up-right"
     data-aos-offset="300"
     data-aos-duration="300"
          >
            <div className="Kiosk_left">
              <h6>KIOSK</h6>
              <p className="para_long">
                Kevell care KIOSK - allows Patients to be monitered/ diagnose/
                communicate with the doctor remotely in Kiosk room;
              </p>
              <p className="para_short">
                The doctor can remotely diagnose with the help of electrical
                medical decices like Stethoscope, otoscope, body temperature,
                blood pressure, height/weight, ECG & SpO2; which is connected
                with KIOSK
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="kiosk_col kiosk_col_img"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
            style={{ paddingRight: "0px" }}
          >
            <div className="Kiosk_right">
              <div className="img_div">
                <img src={kioskImage} alt="kiosk image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Homekit section  ----------------------------------------------- */}

      <section>
        <Row className="Homekit_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="homekit_col"
            style={{ paddingRight: "0px" }}
            data-aos="fade-up-right"
     data-aos-offset="300"
     data-aos-duration="300"
          >
            <div className="Homekit_left">
              <div className="img_div">
                <img src={homekitImage} alt="Homekit image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="homekit_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="Homekit_right">
              <h6>HOME KIT</h6>
              <p className="para_long">
                Kevell Care’s HOME KIT gives big medical support to Patients at
                home. So the Patients need not always stay at hospitals or
                clinics. The devices notify in case of any emergency so that
                Patients can contact doctors immediately using our kevell care
                software/ Mobile App
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  moblie application section  ------------------------------------ */}

      <section className="moblie_application_main">
        <div data-aos="fade-up">
          <h1 className="moblie_main_title">MOBILE APPLICATION</h1>
          <p className="moblie_main_description">
            Kevell care supports Three android based mobile applications like
          </p>
          <p className="moblie_main_description">
            KIOSK app, Doctor app and Patient app
          </p>
        </div>

        <Row className="moblie_application_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            xxl="6"
            style={{ paddingRight: "0px" }}
            data-aos="fade-up"
          >
            <div className="moblie_application_left">
              <h6>Doctor App</h6>
              <p className="mobile_para_long">
                It is mobile application (Android) created for doctor use; can
                install and use in any android based devices like mobile &
                tablet
              </p>
              <p className="mobile_para_long">
                By using this application, doctor can connect with Patient and
                can call the medical devices from this web application and
                control the devices and get the reading from KIOSK / Home kit.
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            xxl="6"
            style={{ padding: "0" }}
            data-aos="fade-up"
          >
            <div className="moblie_application_right">
              <img src={doctorImage} alt="doctor Image" style={{ top: "0" }} />
            </div>
          </Col>
        </Row>

        <Row className="moblie_application_secondrow ">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            xxl="6"
            style={{ padding: "0" }}
                data-aos="fade-up"
          >
            <div className="moblie_application_left">
              <h6>Patient App</h6>
              <p className="mobile_para_long">
                A Patient can use the Home kit with all the medical devices
                connected and by using the Patient App, they can connect with
                thw Doctor and get diagnosed.
              </p>
            </div>
            <div className="moblie_application_left">
              <h6>Patient KIOSK App</h6>
              <p className="mobile_para_long">
                The android based KIOSK app will installed in a bigger display
                android device in a KIOSK; and Patient can be used this KIOSK
                and get connected with the scheduled doctor.
              </p>
              <p className="mobile_para_long">
                This app allows Patient's to be monitored / diagnose /
                Communicate with thw doctor remotely in KIOSK room
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            xxl="6"
            style={{
              paddingRight: "0px",
              display: "flex",
              alignItems: "center",
            }}
                data-aos="fade-up"
          >
            <div className="moblie_application_right">
              {/* <img src={mobileArrowImage} className='mobile_arrow_image' alt="mobileArrowImage" style={{top:'initial',width: '80%',}} /> */}
              <img src={patientAppImage} style={{ top: "0" }} />
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  SMART KIOSK section  ------------------------------------ */}

      <section>
        <Row className="smart_kisok_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="smart_kisok_col smart_kisok_col_img"
            style={{ paddingRight: "0px",paddingLeft:'0px' }}
                        data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="smart_kisok_left">
              <div className="img_div">
                <img src={smartKioskImage} alt="smart Kiosk Image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="smart_kisok_col"
                        data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="smart_kisok_right">
              <h6>SMART KIOSK</h6>
              <p className="para_long">
                The android based app will be installed in a Smart Television;
                so from home by sitting in the couch itself the Patient can get
                connected with the scheduled doctor.
              </p>
              <p className="para_long">
                This app allows Patients to be monitored / diagnose /
                communicate with the doctor remotely with smart KIOSK.
              </p>
              <p className="para_long">
                The doctor can remotely diagnose with the help of USB based
                electrical medical devices like stethoscope, otoscope, body
                temperature, blood pressure, height/weight, ECG & sPO2 which is
                connected with the Smart KIOSK.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  smart_watch section  ----------------------------------------------- */}

      <section>
        <Row className="smart_watch_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="smart_watch_col"
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="smart_watch_left">
              <h6>SMART WATCH</h6>
              <p className="para_long">
                Kevell care app is installed in the Smart Watch; by using this
                Smart Watch, the Patient can connect with the scheduled doctor
                and can able to monitor, diagnose and communicate with the
                doctor
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="smart_watch_col"
            style={{ paddingRight: "0px" }}
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="smart_watch_right">
              <div className="img_div">
                <img src={smartwatchImage} alt="smart watch image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  our service section  ----------------------------------------------- */}

      <section id="Service">
        <Row className="our_service_row">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            xxl="12"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            data-aos="zoom-in-up"
          >
            <div className="our_service_main">
              <h6>Our Services</h6>
              <p>
                This app allows Patient's to be monitored / diagnose /
                communicate with the doctor remotely with smart KIOSK.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Stethoscope section  ----------------------------------------------- */}

      <section>
        <Row className="Stethoscope_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            className="Stethoscope_col"
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="Stethoscope_left">
              <div className="img_div">
                <img src={StethoscopeImage} alt="Stethoscope Image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="Stethoscope_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="Stethoscope_right">
              <h6>Stethoscope</h6>
              <p className="para_long">
                Electrical stethoscope is used connect your existing
                stethoscope’s chestpiece to the CORE Digital Attaachment to take
                reading and it sends to doctor through the ORD App. So the
                physician can hear the heart/lungs/bowel beat without any
                interruptions.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Otoscope section  ----------------------------------------------- */}

      <section>
        <Row className="Otoscope_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="otoscope_col"
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="Otoscope_left">
              <h6>Otoscope</h6>
              <p className="para_long">
                The camera enabled otoscope Angle the tip of the viewing piece
                slightly towards the person’s nose to follow move it gently at
                different angles so that you can see the ear canal walls and the
                Eardrum.
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            className="otoscope_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="Otoscope_right">
              <div className="img_div">
                <img src={OtoscopeImage} alt="Otoscope Image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Body Temperature section  ----------------------------------------------- */}

      <section>
        <Row className="body_temperature_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="body_temperature_col"
            style={{ paddingLeft: "0px", zIndex: "50" }}
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="body_temperature_left">
              <div className="img_div">
                <img src={bodyTemperatureImage} alt="body temperature Image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="body_temperature_col"
            style={{ zIndex: "50" }}
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="body_temperature_right">
              <h6>Body Temperature</h6>
              <p className="para_long">
                Place the censor head at the centre of the forehead an then body
                temperature of the Patient will be send to Physician Screen.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Blood Oxygen section  ----------------------------------------------- */}

      <section>
        <Row className="blood_oxygen_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="blood_oxygen_col"
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="blood_oxygen_left">
              <h6>Blood Oxygen</h6>
              <p className="para_long">
                A pulse oximeter is a device that checks to see how much oxygen
                (Sp02) your blood is carrying and it shows in doctor screen
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            className="blood_oxygen_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="blood_oxygen_right">
              <div className="img_div">
                <img src={bloodOxygenImage} alt="blood Oxygen Image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  glucometer section  ----------------------------------------------- */}

      <section>
        <Row className="glucometer_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="glucometer_col"
            style={{ paddingLeft: "0px", zIndex: "50" }}
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="glucometer_left">
              <div className="img_div">
                <img src={glucometerImage} alt="glucometer Image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="glucometer_col"
            style={{ zIndex: "50" }}
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="glucometer_right">
              <h6>Glucometer</h6>
              <p className="para_long">
                A glucometer is a small, portable device that lets you check
                your blood sugars (glucose levels) through device. no matter
                what type of diabetes you have, a glucometer can give you
                valuable information
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  Blood pressure section  ----------------------------------------------- */}

      <section>
        <Row className="blood_pressure_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="blood_pressure_col"
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="blood_pressure_left">
              <h6>Blood pressure</h6>
              <p className="para_long">
                The blood pressure machine is used to take your measurements
                When reading is complete, the KIOSK monitor displays your blood
                pressure and pulse reading.
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            className="blood_pressure_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="blood_pressure_right">
              <div className="img_div">
                <img src={bloodPressureImage} alt="blood Pressure Image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  height & weight section  ----------------------------------------------- */}

      <section>
        <Row className="height_weight_row">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            className="height_weight_col"
            style={{ paddingLeft: "0px", zIndex: "50" }}
            data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="height_weight_left">
              <div className="img_div">
                <img src={heightWeightImage} alt="height Weight Image" />
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="7"
            xl="7"
            xxl="7"
            className="height_weight_col"
            style={{ zIndex: "50" }}
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="height_weight_right">
              <h6>Height / weight</h6>
              <p className="para_long">
                When a face is identified, your phone will automatically display
                the height / weight. This application will calculate the show
                BMI, Body fat & visceral fat index.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  ECG section  ----------------------------------------------- */}

      <section>
        <Row className="ecg_row">
          <Col xs="12" sm="12" md="6" lg="7" xl="7" xxl="7" className="ecg_col" 
          data-aos="fade-up-right"
            data-aos-offset="300"
            data-aos-duration="300">
            <div className="ecg_left">
              <h6>ECG</h6>
              <p className="para_long">
                Portable electrocardiogram (ECG) Capturing device can be
                interfaced to a smart phone installed with an android-based
                application (ORD)
              </p>
              <p className="para_long">
                The electronic ECG device gets the reading from the Patient and
                shows as ECG graph in doctor’s screen for the further diagnosis
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="5"
            xl="5"
            xxl="5"
            style={{ paddingRight: "0px" }}
            className="ecg_col"
            data-aos="fade-up-left"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <div className="ecg_right">
              <div className="img_div">
                <img src={ecgImage} alt="ecg Image" />
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* ---------------------------------------------------  work flow section  ----------------------------------------------- */}

      <section className="workFlow_main">
        <h6>Kevell care </h6>
        <h5>Work Flow</h5>
        <ul className="workFlow_path">
          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Patient Registration </h6>
              <p>
                Input your demographic information, including personal and
                contact information & upload their medical history as PDF
                document.
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Choose Doctor</h6>
              <p>
                Book what specialist doctor you need and find experienced
                doctors across all over the world availability, feels,
                experience, ratings etc.
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Book appointment</h6>
              <p>
                Patients can book an appointment online at{" "}
                <a href="">www.kevell.care</a> with date, reason to meet doctor
                and pre existing disease.
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Visit KIOSK / Use home kit</h6>
              <p>
                On appointment date/time, Patient need to present in front of
                KIOSK machine or at home they can use home kit connected with
                the medical devices like Stethoscope, Otoscope, Temperature,
                bleed pressure, height/weight, ECG & SpO2.
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Connect with doctor </h6>
              <p>
                Stay connect with your doctor anytime, anywhere 24/7 doctors
                just for you. Completely secure and convenient. The doctor might
                ask you for a video consultation if required later.{" "}
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Enable video conference </h6>
              <p>
                Video conference facility is available to connect with your
                doctor at anytime and anywhere.
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Get Diagnosed by doctor </h6>
              <p>
                Doctor can be able to diagnose your condition and prescribe
                medication over the KIOSK / home kit app by checking your
                medical records and latest medical readings.{" "}
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Get prescription from doctor </h6>
              <p>
                You can get your prescription printed and handover to you in
                KIOSK and the same will be available in the kevell care portal,
                you will be able to view/download your prescription.{" "}
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>Schedule next appointment </h6>
              <p>
                Your next appointment can be scheduled by your doctor and that
                will be available in the calendar and Patient will get the
                notification too.{" "}
              </p>
            </div>
          </li>

          <li class="flow_item">
            <div class="flow_content_main">
              <h6>View prescription / Reports </h6>
              <p>
                You can find your prescription for your online consultation on
                your profile page in the kevell care portal, you will be able to
                view/ downloaded your prescription.{" "}
              </p>
            </div>
          </li>
        </ul>
      </section>

      {/* ---------------------------------------------------  board member section  ----------------------------------------------- */}

      <section className="board_member_main_section">
        <div>
          <div className="member_card_first">
            <h5>Our executive board members</h5>
            <div className="member_card_main">
              <div className="member_card">
                <img src={boardMember1} alt="board Members" />
                <div className="member_card_content">
                  <h6>Dr. Gowtham</h6>
                  <p>Orthopedic surgeon </p>
                </div>
              </div>
              <div className="member_card">
                <img src={boardMember2} alt="board Members" />
                <div className="member_card_content">
                  <h6>Dr. Priya</h6>
                  <p>Gynecologist </p>
                </div>
              </div>
            </div>
          </div>

          <div className="member_card_secord">
            <h5>We offer fair prices for E-medicines</h5>
            <p>Call us for Appointment</p>
            <h3>+91 7010212129</h3>
          </div>
        </div>

        <div className="member_card_third">
          <h6>Our Testimonials</h6>
          <Carousel className="carousel_testimonials">
            <Carousel.Item className="carousel_testimonials_item">
              <Carousel.Caption
                style={{
                  position: "initial",
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <div className="carousel_testimonials_main">
                  <div className="img_main">
                    <div className="img_div">
                      <img src={testimonialsImage} alt="testimonialsImage" />
                    </div>
                    <span>
                      <ImQuotesLeft />
                    </span>
                  </div>
                  <p>
                    I really do not have anything negative to say about Kevell
                    Care. Every time I have used this application I have been
                    happy.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel_testimonials_item">
              <Carousel.Caption
                style={{
                  position: "initial",
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <div className="carousel_testimonials_main">
                  <div className="img_main">
                    <div className="img_div">
                      <img src={testimonialsImage} alt="testimonialsImage" />
                    </div>
                    <span>
                      <ImQuotesLeft />
                    </span>
                  </div>
                  <p>
                    From my experience with Kevell Care was a positive one.
                    Highly professional doctors.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel_testimonials_item">
              <Carousel.Caption
                style={{
                  position: "initial",
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <div className="carousel_testimonials_main">
                  <div className="img_main">
                    <div className="img_div">
                      <img src={testimonialsImage} alt="testimonialsImage" />
                    </div>
                    <span>
                      <ImQuotesLeft />
                    </span>
                  </div>
                  <p>
                    I would highly recommend Kevell Care to others. It is a fast
                    and easy way to speak with a doctor and to get treated.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel_testimonials_item">
              <Carousel.Caption
                style={{
                  position: "initial",
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <div className="carousel_testimonials_main">
                  <div className="img_main">
                    <div className="img_div">
                      <img src={testimonialsImage} alt="testimonialsImage" />
                    </div>
                    <span>
                      <ImQuotesLeft />
                    </span>
                  </div>
                  <p>
                    No problems whatsoever and actually save time and money by
                    not having to leave home to visit with my doctors.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel_testimonials_item">
              <Carousel.Caption
                style={{
                  position: "initial",
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <div className="carousel_testimonials_main">
                  <div className="img_main">
                    <div className="img_div">
                      <img src={testimonialsImage} alt="testimonialsImage" />
                    </div>
                    <span>
                      <ImQuotesLeft />
                    </span>
                  </div>
                  <p>
                    Kevell Care is a perfect solution for the times when you're
                    not feeling well but do not necessarily need to visit a
                    physician in person. As a result, there is a saved cost.
                  </p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <div></div>
        </div>
      </section>

      {/* --------------------------------------------------- e-medicine platform section  ----------------------------------------------- */}

      <section className="e-medicine_main">
        <Row className="e-medicine_row">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            xxl="6"
            className="e-medicine_col"
          >
            <div className="e-medicine_left_side">
              <img src={eMedicinePlatform} alt="eMedicine Platform" />
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            xxl="6"
            className="e-medicine_col"
          >
            <div className="e-medicine_right_Side">
              <h6>
                We are a certified award winning E-medicine platform you can
                trust
              </h6>
              <p>
                Kevell care enables care at less cost to the Patient, often a
                time- saving way for consumer to see and speak to a clinician
                for minor and non-urgent medical needs instead of going to a
                primary care physician’s office or emergency department.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* --------------------------------------------------- experienced doctor section  ----------------------------------------------- */}

      <section className="experienced_doctor_main">
        <div className="experienced_doctor_content">
          <h6>Professional doctors </h6>
          <h4>Meet our Certified & experienced doctors </h4>

          <Carousell
            className="carousel_experienced_doctor"
            // swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  // ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  arrows={false}
  // autoPlaySpeed={1000}
  // keyBoardControl={true}
  // customTransition="all .5"
  // transitionDuration={500}
  // containerClass="carousel-container"
  // removeArrowOnDeviceType={["tablet", "mobile","laptop"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
          >
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                  <div className="img_div">
                    <img src={experiencedDoctor1} alt="Doctor image" />
                  </div>
                  <div className="carousel_experienced_doctor_content">
                    <h5>Dr.Hemaleka </h5>
                    <p>Gynecologist / obstetrician</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                  <div className="img_div">
                    <img src={experiencedDoctor2} alt="Doctor image" />
                  </div>
                  <div className="carousel_experienced_doctor_content">
                    <h5>Dr.Saravanan </h5>
                    <p>General Physician</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                  <div className="img_div">
                    <img src={experiencedDoctor4} alt="Doctor image" />
                  </div>
                  <div className="carousel_experienced_doctor_content">
                    <h5>Dr.Krishnaswamy </h5>
                    <p>Cardiologist</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                    <div className="img_div">
                      <img src={experiencedDoctor5} alt="Doctor image" />
                    </div>
                    <div className="carousel_experienced_doctor_content">
                      <h5>Dr.Gnaneswaran </h5>
                      <p>Nephrologist</p>
                    </div>
                  </div>
              </div>
            </div>
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                    <div className="img_div">
                      <img src={experiencedDoctor6} alt="Doctor image" />
                    </div>
                    <div className="carousel_experienced_doctor_content">
                      <h5>Dr.jessica </h5>
                      <p>General Physician</p>
                    </div>
                  </div>
              </div>
            </div>
            <div className="carousel_experienced_doctor_item">
              <div className="carousel_experienced_doctor_main">
                <div className="experienced_doctor_card">
                    <div className="img_div">
                      <img src={experiencedDoctor3} alt="Doctor image" />
                    </div>
                    <div className="carousel_experienced_doctor_content">
                      <h5>Dr.Stella </h5>
                      <p>Gynecologist / obstetrician</p>
                    </div>
                  </div>
              </div>
            </div>
          </Carousell>
        </div>

        <section className="constact_us_main" id="ContactUs">
          <img
            src={contactUsbackimage}
            className="contact_background_image"
            alt="Contact Us Image"
          />
          <div className="contact_content_main">
            <h5>Contact Us</h5>
            <form ref={form}>
              <div className="input_row">
                <input
                  type="text"
                  name="first_Name"
                  id="first_Name"
                  placeholder="First name"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="last_Name"
                  id="last_Name"
                  placeholder="Last name"
                  autoComplete="off"
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  placeholder="Phone"
                  autoComplete="off"
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E mail"
                  autoComplete="off"
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Location"
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </div>
              <div className="input_row">
                <input
                  type="url"
                  id="url"
                  name="url"
                  placeholder="URL"
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </div>
              <button onClick={formSubmit}>Submit</button>
            </form>
          </div>
        </section>

        <section className="footer_main">
          <div className="footer_main_content">
            <div className="footer_left">
              <h6>Get in Touch</h6>
              <ul>
                <li>
                  <BsFillTelephoneFill className="footer_icons" />
                  <span>+91 7010212129</span>
                </li>
                <li>
                  <HiLocationMarker className="footer_icons" />
                  <span>
                    33, Chitra madhavan enclave, hakim ajmal khan road <br></br>
                     (near lady doak college back gate) Chinnachokkikulam,<br></br>
                    Madurai-625002
                  </span>
                </li>
                <li>
                  <MdMail className="footer_icons" />
                  <span>health@kevell.care</span>
                </li>
              </ul>
            </div>
            <div className="footer_right">
              <h6>Follow us</h6>
              <ul>
                <li>
                  <a href="https://twitter.com/kevellcare" target="_blank" className="social_icon_img">
                    <img src={followsImage1} alt="social icon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/kevellcare/" target="_blank" className="social_icon_img">
                    <img src={followsImage2} alt="social icon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kevell_care/" target="_blank" className="social_icon_img">
                    <img src={followsImage3} alt="social icon" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/kevell-care" target="_blank" className="social_icon_img">
                    <img src={followsImage4} alt="social icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p className="copy_rights">
            Copyright @ 2024 Kevell corp. All Rights Reserved
          </p>
        </section>
      </section>

      {/* ---------------------------------------------------  Qr application section  ----------------------------------------------- */}


    {/* <section id="qrApplication">
        <div className='qr_main_div'>
            <h6>Install Our KEVELL CARE application</h6>
            <Row className='qr_row_main'>
                <Col xs="12" sm="6" md="6" lg="6" xl="5" xxl="5" className='qr_col_main'>
                    <div className='qr_left'>
                        <img src={mobileQrImage} alt="mobile Qr Image" />
                    </div>
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="5" xxl="5" className='qr_col_main'>
                    <div className='qr_right'>
                        <h6>Scan our QR Code</h6>
                        <img src={qrAppImage} alt="qr App Image" />
                        <h6>To install our app</h6>
                    </div>
                </Col>
            </Row>
        </div>
    </section> */}


    </div>
  );
}

export default Home